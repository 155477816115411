import { useLazyQuery } from '@apollo/client';
import { ApolloClientService } from '@mono/apollo';
import { logError } from '@mono/data/logging';
import { useUserJwt } from '@mono/data/user';
import noop from 'lodash/noop';
import {
  Context,
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { DEFAULT_SORT_BY } from '../../const';
import { getPaginatedCatalogResults } from '../../helpers';
import { paginatedCatalogQuery } from '../../queries.graphql-gateway';
import { PaginatedCatalogResults } from '../../types';
import {
  getSelectedFiltersDefault,
  SelectedFilters,
} from '../FilterMenus/Filters/types';
import { SortBy } from '../SortByDropdown/types';
import { getFilterCount, getQueryVariables } from './helpers';

export interface PaginatedCatalogContextValue {
  selectedFilters: SelectedFilters;
  setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>;
  sortBy: SortBy;
  setSortBy: Dispatch<SetStateAction<SortBy>>;
  filterCount: number;
  resetSelectedFilters: () => void;
  currPageNum: number;
  setCurrPageNum: Dispatch<SetStateAction<number>>;
  triggerFetch: () => void;
  results: PaginatedCatalogResults;
  loading: boolean;
}

const defaultPaginatedCatalogContextValue: PaginatedCatalogContextValue = {
  selectedFilters: getSelectedFiltersDefault(),
  setSelectedFilters: noop,
  sortBy: SortBy.POPULAR,
  setSortBy: noop,
  filterCount: 0,
  resetSelectedFilters: noop,
  currPageNum: 0,
  setCurrPageNum: noop,
  results: { pageItems: [], totalResults: 0, totalPages: 0 },
  triggerFetch: noop,
  loading: false,
};

export const PaginatedCatalogContext: Context<PaginatedCatalogContextValue> =
  createContext(defaultPaginatedCatalogContextValue);

export interface PaginatedCatalogContextProps {
  initialCatalogResults: PaginatedCatalogResults;
  children?: React.ReactNode;
}

export const PaginatedCatalogContextProvider: React.FC<
  PaginatedCatalogContextProps
> = ({ children, initialCatalogResults }) => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(
    getSelectedFiltersDefault()
  );

  const [sortBy, setSortBy] = useState<SortBy>(DEFAULT_SORT_BY);

  const [currPageNum, setCurrPageNum] = useState(1);

  const [results, setResults] = useState(initialCatalogResults);

  const [loading, setLoading] = useState<boolean>(false);

  const [fetchTriggered, setFetchTriggered] = useState<boolean>(false);

  const jwt = useUserJwt();

  const [fetchCatalogResults] = useLazyQuery(paginatedCatalogQuery, {
    context: {
      service: ApolloClientService.GraphqlGateway,
      headers: {
        ...(jwt ? { Authorization: `Bearer ${jwt}` } : {}),
      },
    },
  });

  const fetchNewResults = useCallback(async () => {
    setLoading(true);
    const { data, error } = await fetchCatalogResults({
      variables: getQueryVariables(selectedFilters, sortBy, currPageNum),
    });
    if (error) {
      logError(`Error fetching PaginatedCatalogResults: ${error}`);
    }
    if (data) {
      const newCatalogResults = getPaginatedCatalogResults(data);

      setResults(newCatalogResults);
    }
    setLoading(false);
    setFetchTriggered(false);
  }, [fetchCatalogResults, selectedFilters, currPageNum, sortBy]);

  useEffect(() => {
    if (fetchTriggered) {
      fetchNewResults();
    }
  }, [fetchTriggered, fetchNewResults]);

  const triggerFetch = () => setFetchTriggered(true);

  const filterCount = getFilterCount(selectedFilters);

  const contextValue: PaginatedCatalogContextValue = {
    selectedFilters,
    setSelectedFilters,
    sortBy,
    setSortBy,
    filterCount,
    resetSelectedFilters: () => {
      setSelectedFilters(getSelectedFiltersDefault());
      setCurrPageNum(1);
      triggerFetch();
    },
    currPageNum,
    setCurrPageNum,
    triggerFetch,
    results,
    loading,
  };

  return (
    <PaginatedCatalogContext.Provider value={contextValue}>
      {children}
    </PaginatedCatalogContext.Provider>
  );
};

export const usePaginatedCatalogContext = () =>
  useContext(PaginatedCatalogContext);
